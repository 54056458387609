import './member.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {imagePath, Spinner} from "../util";

// eslint-disable-next-line
import log from 'loglevel';

export function Member() {
    const memberId = useParams().memberId;
    const [member, setMember] = useState(undefined);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        document.title = "Numento - " + member?.Name;
    });

    useEffect(() => {
        axios.get(`/items/members/${memberId}`)
            .then(response => {
                console.log("response", response);
                setMember(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [memberId]);

    if (!member) {
        return Spinner();
    }

    return (
        <div className="memberPage">
            <div className="memberLeft">
                <div className="memberPicture">
                    <img src={imagePath(member?.image)} alt={member?.Name} onLoad={() => setLoaded(true)}/>
                </div>
                {loaded 
                ? <>
                  <div className='memberInfo'>
                      <h2>{member?.Name}</h2>
                      <h2>{member?.instrument}</h2>
                  </div>
                  <div className='memberSocial'>
                    {member?.links?.map((link) => {
                        return (
                            <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                        );
                    })}
                  </div>
                  <div className='memberStory'>
                    <p>{member?.introduction}</p>
                  </div>
                  </>
                 : Spinner()}
            </div>
        </div>
    );
}

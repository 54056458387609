import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const LinksContext = createContext();  

export function LinksProvider({ children }) {
    const [links, setLinks] = useState([]);

    useEffect(() => {
      axios.get('/items/links')
      .then(response => {
          setLinks(response.data.data);
      })
      .catch(error => {
          console.error('There was an error!', error);
      });        
    }, []); 

    return (
        <LinksContext.Provider value={links}>
            {children}
        </LinksContext.Provider>
    );
}

export function useLinksContext() { 
  return useContext(LinksContext);
}



import './gigs.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import { Divider, Space, Button } from 'antd';
import { format } from 'date-fns';
import { FacebookFilled } from '@ant-design/icons';
import { CustomScroll } from 'react-custom-scroll';
// eslint-disable-next-line
import log from "loglevel";
import { Spinner } from '../util';

export function Gigs() {

    const [gigs, setGigs] = useState([]);

    useEffect(() => {
        document.title = 'Numento - Gigs';
    });
    useEffect(() => {
        axios.get('/items/gigs')
            .then(response => {
                setGigs(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);


    if (gigs.length === 0) {
        return Spinner();
    }
    const currentDate = new Date();

    const upcomingGigs = gigs.filter(gig => new Date(gig.date) >= currentDate)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
        
    const pastGigs = gigs.filter(gig => new Date(gig.date) < currentDate)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
      <CustomScroll heightRelativeToParent="100%">
        <div className="gigs-page">
          <div className="gigs-list">
              <h2>Upcoming gigs</h2>
              <div className="gigs-container">
                  {upcomingGigs.map((gig) => {
                      return (
                        <div>
                            <div key={"upcoming_" + gig.id} className="gig-item">
                                  <div className='gig-details'>
                                      <p>{format(new Date(gig.date), 'EEEE, MMMM d, yyyy')}</p>
                                      <p className="gig-place">{gig.place}</p>
                                  </div>
                                  <div className='gig-links'>
                                      <p>{gig.city}</p>
                                      <p>{gig.country}</p>
                                      <div className="gig-buttons">
                                        <Space>
                                          {gig.ticket_url && <Button type="primary" href={gig.ticket_url} target='_blank'>Buy tickets</Button>}
                                          {gig.facebook_url && <Button type="primary" href={gig.facebook_url} target='_blank' icon={<FacebookFilled/>}/>}
                                        </Space>
                                      </div>
                                  </div>
                            </div>
                            <div className='gig-extra-info'>
                                <p>{gig.extra_info}</p>
                            </div>
                            <Divider />
                      </div>
                      );
                  })}
              </div>
              <h2>Past gigs</h2>
              <div className="gigs-container">
                {pastGigs.map((gig) => {  
                    return (
                        <>
                            <div key={"past_" + gig.id} className="gig-item">
                              <div className='gig-details'>
                                  <p>{format(new Date(gig.date), 'EEEE, MMMM d, yyyy')}</p>
                                  <p>{gig.place}</p>
                              </div>
                              <div className='gig-links'>
                                  <p>{gig.city}</p>
                                  <p>{gig.country}</p>
                                  <div className="gig-buttons">
                                    {gig.facebook_url && <Button type="primary" href={gig.facebook_url} target='_blank' icon={<FacebookFilled/>}/>}
                                  </div>
                              </div>
                            </div>
                            <Divider />
                        </>
                    );
                  })
                }
              </div>
          </div>
        </div>
      </CustomScroll>
    );
}

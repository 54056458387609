import './band.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Space, Divider } from 'antd';
import { CustomScroll } from 'react-custom-scroll';

import { imagePath, Spinner } from '../util';

// eslint-disable-next-line
import log from 'loglevel';

export function Band() {
    const [band, setBand] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        document.title = 'Numento - Band';
    });

    useEffect(() => {
        axios.get('/items/band')
            .then(response => {
                setBand(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    if (band.length === 0) {
        return Spinner();
    }

    
    return (
        <CustomScroll heightRelativeToParent="100%">            
            <div className="bandPage">            
                <img src={imagePath(band?.band_photo)} alt="Numento" onLoad={() => setLoaded(true)} />
                {loaded ?
                  <>
                    <div>
                        <nav>
                            <Space direction="horizontal">
                                <Link to="/band/5"><h2>Simo</h2></Link>
                                <Link to="/band/2"><h2>Atte</h2></Link>
                                <Link to="/band/1"><h2>Katri</h2></Link>
                                <Link to="/band/3"><h2>Aleksi</h2></Link>
                                <Link to="/band/4"><h2>Mikko</h2></Link>
                            </Space>
                        </nav>
                    </div>
                    <div className='history'>
                        <Divider style={{margin: '10px 0'}}orientation='left'><h3>Band bio</h3></Divider>
                        <div className="bandInfo">
                            {band.history}
                        </div>
                    </div>
                  </>
                  : Spinner()}
            </div>
        </CustomScroll>
    );
}

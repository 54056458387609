import './App.css';
import './styles/fonts.css';

import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';  
import axios from 'axios';
import { useOutlet, Link } from 'react-router-dom';
import { Button, Menu, Space } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';

import {  MenuOutlined } from '@ant-design/icons';
import { useWindowSizeContext } from './SettingContext';
import { IconRenderer, imagePath } from './util';
import { useLinksContext } from './LinkContext';

function Webpage() {
    const outlet = useOutlet();
    const headerRef = useRef(null);
    const pageRef = useRef(null);
    const { width, height } = useWindowSizeContext();
    const [ main, setMain] = useState(undefined);
    const lastPart = window.location.pathname.split('/').pop();
    const [ current, setCurrent] = useState(lastPart);  
    const [ menuVisible, setMenuVisible] = useState(false);
    const [ isMobile, setIsMobile] = useState(width < 768);
    const links = useLinksContext();

    const adjustMargin = useCallback(() => {
        if (headerRef.current && pageRef.current) {
            log.info("headerRef.current.clientHeight", headerRef.current.clientHeight);
            if(headerRef.current.clientHeight <  200) {

                pageRef.current.style.marginTop = '334px';
            } else {
                pageRef.current.style.marginTop = headerRef.current.clientHeight + 'px';
            }
        } else {
            setTimeout(adjustMargin, 100);
        }
    }, [headerRef, pageRef]);

    useEffect(() => {
        document.title = 'Numento';
    });

    useEffect(() => {
        axios.get('/items/Main')
            .then(response => {
                setMain(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    useEffect(() => {
        setIsMobile(width < 768);
        setMenuVisible(false);
        adjustMargin();
    }, [width, adjustMargin]);

    useLayoutEffect(() => {
        log.info("useLayoutEffect");
        adjustMargin();
    }, [adjustMargin]);  

    log.info("links", links); 

    if (height === 0 || width === 0 || main === undefined || links.length === 0) {  
        return <div/>;
    }
    
    const onClick = (e) => {
        setCurrent(e.key);
        setMenuVisible(false);
    }

    log.info("Main", main); 

    const items = [
        {
          key: 'home', 
          label: <Link to="/home">Home</Link>
        },
        {
          key: 'news', 
          label: <Link to="/news">News</Link>
        },
        {
          key: 'discography', 
          label: <Link to="/discography">Discography</Link>
        },
        {
          key: 'band', 
          label: <Link to='/band'>Band</Link>
        },
        {
          key: 'gigs', 
          label: <Link to='/gigs'>Gigs</Link>
        },
        {
          key: 'gallery', 
          label: <Link to='/gallery'>Gallery</Link>
        },
        {
          key: 'contact', 
          label: <Link to='/contact'>Contact</Link>
        }
    ];

    const siteLogoUrl = imagePath(main.side_logo);
    return (
        <div className="content"> 
            <header className="app-header" ref={headerRef}>
                <div className="corner top-left" style={{backgroundImage: `url(${siteLogoUrl})`}}></div>
                <div className="corner top-right" style={{backgroundImage: `url(${siteLogoUrl})`}}></div>
                <div>
                    <Link to="/home">
                        <img className="app-logo" src={imagePath(main?.logo)} alt="Numento" />
                    </Link>
                </div>
                { isMobile ? (
                    <> 
                      <div className="menu">
                        <Button style={{fontSize: "1.4em"}} size="large" type="plain" onClick={() => setMenuVisible(!menuVisible)} icon={<MenuOutlined />}>Menu</Button> 
                        {menuVisible && ( 
                          <Menu 
                            style={{ width: "100%", fontSize: "1.4em" }}
                            onClick={onClick} 
                            selectedKeys={[current]} 
                            mode="vertical" 
                            items={items} 
                            theme="dark"                      
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Menu 
                      style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "1em", flex: "auto", minWidth: 0 }}
                      onClick={onClick} 
                      selectedKeys={[current]} 
                      mode="horizontal" 
                      items={items} 
                      theme="dark"                      
                    />
                  )
                }
            </header>
            <div className="page" ref={pageRef}>
              {outlet}
            </div>
            <div className='footer'>
                <Space size="large" className="icons">
                  {main?.links?.map((mainLink, index) => {
                    const linkItem = links.find(link => link.id === mainLink);
                    return (
                      <a key={index} href={linkItem?.link} target="_blank" rel="noopener noreferrer">
                        {IconRenderer(linkItem)}
                      </a>
                    )
                  })}
                </Space>
                <span className="copyright">Copyright © Numento 2025</span>
            </div>
        </div>
    );
}

export default Webpage;

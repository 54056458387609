import React from 'react';
import getIconComponent from './getIconComponent';

/* eslint-disable no-unused-vars */
import log from 'loglevel';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const imagePath = (imageId) => {
    return "/assets/" + imageId;
} 


export const IconRenderer = (link) => {
  const {link_name} = link;
  if (link_name.length === 0) { 
    return (
      <div>  
        <img src={imagePath(link.link_icon)} alt="BandCamp"/>
      </div>
    );
  } 
  return (
    <div>
      {getIconComponent(link_name)}
    </div>
  );
};


export const Spinner = () => {
  return <div className='spinner'>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />} />
  </div>
}

import './gallery.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import log from 'loglevel';


import { imagePath, Spinner } from '../util';
import { CustomScroll } from 'react-custom-scroll';
import { Flex, Image, Space } from 'antd';
import { LeftOutlined, RightOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, UndoOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

export function Gallery() {
    const galleryId = useParams().galleryId;
    const [gallery,setGallery] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [current, setCurrent] = React.useState(0);
  

    useEffect(() => {
        document.title = 'Numento - ' + gallery?.header;
    });

    useEffect(() => {
        axios.get(`/items/galleries/${galleryId}`)
            .then(response => {
                log.info("Gallery", response.data.data);
                setGallery(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [galleryId]);

    useEffect(() => {
      axios.get(`/items/galleries_files`)
          .then(response => {
              log.info("Files", response.data.data);
              setFiles(response.data.data);
          })
          .catch(error => {
              console.error('There was an error!', error);
          });
    }, []);

    if (!gallery || files.length === 0) {
        return Spinner();
    } 
  
    const imageList = gallery?.images.map((image) => { 
      return files.find(file => file.id === image);
    });

    if (imageList.length === 0) {
      return Spinner();
    }
    
    log.info("Images", imageList);
    return (
      <CustomScroll heightRelativeToParent="100%">
          <div className="galleryPage">
              <Flex className='galleryEntry' wrap gap="large" justify="space-around">
                <Image.PreviewGroup
                  preview={{
                    maskStyle: { background: 'rgba(0, 0, 0, 0.95)' },
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onActive,
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                          onReset,
                        },
                      },
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <LeftOutlined onClick={() => onActive?.(-1)} />
                        <RightOutlined onClick={() => onActive?.(1)} />
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                        <UndoOutlined onClick={onReset} />
                      </Space>      
                    ),
                    onChange: (index) => {
                      setCurrent(index);
                    },
                  }}
                >
                  {imageList.map((item, index) => (
                    <Image key={index} src={imagePath(item.directus_files_id)} width={300} style={{objectFit:'cover', aspectRatio: '1', transition:'transform 0.2s', borderRadius:9}} />                    
                  ))}
                </Image.PreviewGroup>                
              </Flex>
          </div>

      </CustomScroll>
  );
}

import './contact.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';
import { Spinner } from '../util';

export function Contact() {
    const [contact, setContact] = useState([]);
    useEffect(() => {
        document.title = 'Numento - Contact';
    });

    useEffect(() => {
        axios.get('/items/contact')
            .then(response => {
                setContact(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    if (contact.length === 0) {
        return Spinner();
    }

    log.info("Contact", contact);
    const createListItems = (data) => {
        const httpsUrlPattern = new RegExp('^https://', 'i');
        const emailPattern = new RegExp('@', 'i');  

        return data.split('\n').map((item, index) => {
            if (httpsUrlPattern.test(item)) {
                return <li key={index}><a href={item} target='_bland'>{item}</a></li>
            } if (emailPattern.test(item)) {
                return <li key={index}><a href={`mailto:${item}`}>{item}</a></li>
            } else {
                return <li key={index}>{item}</li>
            }
        });
    }
        
    return (
        <div className='contact-page'>
          <Flex className='flex-settings' wrap gap="middle" justify="space-around">
            <div className='text-element'>
              <h2>Band emails</h2>
              <ul>{createListItems(contact?.email || '')}</ul>
            </div>
            <div className='text-element'>
              <h2>Booking information</h2>
              <ul>{createListItems(contact?.booking || '')}</ul>
            </div>
            <div className='text-element'>
              <h2>Thanks</h2>
              <ul>
                <li>
                    <div>
                      Band photos:
                    </div>
                    <div>
                      Kai Lukander
                    </div>
                </li>

              </ul>
            </div>
          </Flex>
        </div>
    );
}

import './news.css';
import axios from "axios";
import React, {useEffect, useState} from "react"; 
import { format } from 'date-fns'
// eslint-disable-next-line
import log from 'loglevel';
import { Divider } from 'antd';
import { CustomScroll } from 'react-custom-scroll';
import { Spinner } from '../util';

export function News() {
    const [news, setNews] = useState([]);
    useEffect(() => {
        document.title = 'Numento - News';
    });

    useEffect(() => {
        axios.get('/items/news')
            .then(response => {
                setNews(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    if (news.length === 0) {
        return Spinner();
    }

    const sortedNews = news.sort((a, b) => new Date(b.date) - new Date(a.date));
    
    const newsItems = sortedNews?.map((newsItem) => { 
      const formattedDate = format(newsItem?.date, 'MMMM dd, yyyy');
      return (        
          <div key={newsItem.id}>
              <p>{formattedDate}</p>
              <h2>{newsItem?.title}</h2>
              <div className="newsData" dangerouslySetInnerHTML={{ __html: newsItem.news_text }} />
              <Divider />
              
          </div>
      );
    });

    return (
        <CustomScroll heightRelativeToParent="100%">
            <div className="newsPage">        
                {newsItems}
            </div>
        </CustomScroll>
    );
}

import "./home.css";
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { imagePath, Spinner } from './../util';

export function Home() {
    const [ main, setMain] = useState(undefined);

    useEffect(() => {
        document.title = 'Numento - Home';
    });

    useEffect(() => {
      axios.get('/items/Main')
          .then(response => {
              setMain(response.data.data);
          })
          .catch(error => {
              console.error('There was an error!', error);
          });        
    }, []);

    if (main === undefined) {
        return Spinner();
    } 

    return (
      <div className="homePage">
          <img src={imagePath(main?.mainPage)} alt="Antimatter" />
          <div style={{textAlign: "center"}}>              
            <Markdown remarkPlugins={[remarkGfm]}>{main?.mainText}</Markdown> 
          </div>
      </div>
    );
}

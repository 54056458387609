import './galleries.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Flex } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';
import { imagePath, Spinner } from '../util';
import { Link } from 'react-router';

const {Meta} = Card;

export function Galleries() {
    const [galleries, setGalleries] = useState([]);
    useEffect(() => {
        document.title = 'Numento - Gallery';
    });

    useEffect(() => {
        axios.get('/items/galleries')
            .then(response => {
                setGalleries(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    log.info("Galleries", galleries);

    if (galleries.length === 0) {
        return  Spinner();

    }

  
    const getAuthor = (author) => {
      return (
        <div className="author">
          {author}
        </div>
      );
    }

    const getTitle = (title) => {
      return (
        <div className="title">
          {title}
        </div>
      );
    }

    const createGalleries = (galleries) => {
      
        return galleries.map((item, index) => {
          if (item?.visible !== true) {
            return null;
          } else {
            return (
              <Link key={item.id} to={`/gallery/${item.id}`}>
                  <Card
                      hoverable
                      style={{ width: 350 }}
                      cover={<img alt="example" src={imagePath(item.cover_image)} />}
                  >
                      <Meta title={getTitle(item?.header)} description={getAuthor(item?.copyright)} />
                  </Card>
              </Link>
            )
          }
        }
      )
    }
        
    return (
        <div className='galleriesPage'>
          <Flex wrap gap="middle" justify="space-around">
            {createGalleries(galleries)}
          </Flex>
        </div>
    );
}
